<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Kode<span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.mlum_code" placeholder="e.g 0001" :formatter="$parent.code" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.mlum_code" 
                    :rules="toValidate(mrValidation.mlum_code)"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Nama<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Nama" type="text" class="form-control" v-model="row.mlum_name"/>
                  <VValidate 
                    name="Nama" 
                    v-model="row.mlum_name" 
                    :rules="toValidate(mrValidation.mlum_name)"
                  />
                </b-form-group>
              </b-col>
              

              <b-col lg="6">
                <b-form-group>
                  <label>Satuan<span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" placeholder="Satuan" type="text" class="form-control" v-model="row.mlum_satuan"/>
                  <VValidate 
                    name="Satuan" 
                    v-model="row.mlum_satuan" 
                    :rules="toValidate(mrValidation.mlum_satuan)"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                    <label>Pilih Kategori<span class="text-danger mr5">*</span></label>
                    <v-select 
                        :options="$parent.mLogistikKategori"
                        v-model="row.mlum_mlk_id"
                        placeholder="Pilih Kategori"
                        label="text"
                    :clearable="true" :reduce="v=>v.value"
                    >
                    </v-select>
                  
                    <VValidate 
                        name="Kategori" 
                        v-model="row.mlum_mlk_id" 
                        :rules="toValidate(mrValidation.mlum_mlk_id)"
                    />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object
  },
  methods: {
    toValidate(val){
      return {...val}
    }
  },
}
</script>